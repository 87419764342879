@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  ::before,
  ::after {
    @apply dark:border-dark-border;
  }

  body {
    @apply bg-white antialiased dark:bg-dark-background;
    -webkit-tap-highlight-color: transparent;
  }
}

@layer components {
  .label {
    @apply block text-[13px] font-bold text-black dark:text-white;
  }

  .input {
    @apply w-full text-[15px] rounded-[1.5rem] border-gray-200 bg-gray-100 p-[0.6875rem] text-gray-800 focus:border-primary-400 focus:ring-0 dark:border-dark-border dark:bg-dark-background dark:text-white;
  }

  .input-error-border {
    @apply border-red-500 focus:border-red-500;
  }

  .input-error {
    @apply text-sm text-red-500;
  }

  .amount-input::-webkit-outer-spin-button,
  .amount-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* .amount-input {
    -moz-appearance: textfield;
  } */
}

@layer utilities {
  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

@font-face {
  font-family: 'CalibreWeb';
  src: url('./fonts/CalibreWeb-Regular.woff2') format('woff2'),
    url('./fonts/CalibreWeb-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'CalibreWeb';
  src: url('./fonts/CalibreWeb-Medium.woff2') format('woff2'),
    url('./fonts/CalibreWeb-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: 'Airtime';
  font-style: normal;
  src: url('./fonts/Airtime/Airtime-Regular.ttf');
  font-weight: 500;
  text-transform: lowercase;
}

/* bricolage-grotesque-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Bricolage Grotesque';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/BricolageGrotesque/bricolage-grotesque-v2-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* bricolage-grotesque-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Bricolage Grotesque';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/BricolageGrotesque/bricolage-grotesque-v2-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* bricolage-grotesque-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Bricolage Grotesque';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/BricolageGrotesque/bricolage-grotesque-v2-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.react-datepicker__input-container input {
  @apply input;
}

.react-datepicker-popper {
  @apply z-20 w-full rounded-lg border bg-white p-[0.5625rem];
}

.react-datepicker {
  @apply flex space-x-4;
}

.react-datepicker__day-names,
.react-datepicker__week {
  @apply flex items-center justify-between;
}

.react-datepicker__day-name {
  @apply text-gray-500;
}

.react-datepicker__day-name,
.react-datepicker__day {
  @apply flex h-8 w-8 items-center justify-center rounded-full text-center text-sm;
}

.react-datepicker__day {
  @apply text-gray-800;
}

.react-datepicker__day--disabled,
.react-datepicker__day--outside-month {
  @apply text-gray-500;
}

.react-datepicker__day--selected {
  @apply bg-primary-400;
}

.react-datepicker__header--time {
  @apply mt-1.5 mb-3;
}

.react-datepicker-time__header {
  @apply text-sm font-medium text-gray-800;
}

.react-datepicker__time-list {
  @apply !h-48 space-y-3 overflow-y-auto;
}

.react-datepicker__time-list-item {
  @apply text-sm text-gray-500;
}

.react-datepicker__time-list-item--selected {
  @apply font-medium text-gray-800;
}
